import React, { } from 'react';
import SiteLayout from '../components/SiteLayout';
import { Location } from '@reach/router';
import { Button } from 'antd';

// @ts-ignore
import Privacy from '../../static/privacy.inline.svg';

// @ts-ignore
import { backbutton, info, image } from './personvern.module.scss';
import { Link } from 'gatsby';

function Index({ props }) {

    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <Privacy className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <p style={{ fontWeight: 400 }}>PERSONVERN</p>
                <br />
                <p className={info}>
                    ((policy))
                </p>

                <Link to="/"><Button icon="arrow-left" className={backbutton}>Tilbake</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Index;
